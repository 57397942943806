<template>
    <b-modal
      id="brasindice-exceptions-modal"
      size="md"
      hide-header
      hide-footer
      centered
      @hidden="onHidden"
    >
        <div class="header d-flex flex-row justify-content-between">
            <p class="title">Novo exceção</p>
            <Close class="close" @click="close" />
        </div>
        <div class="content">
            <b-row>
                <b-col cols="12">
                    <b-form-group>
                        <label>Convênio</label>
                        <HealthPlanSelect 
                            required
                            :clinicId="clinic.id"
                            placeholder="Selecione um convênio"
                            v-model="form.clinic_health_plan_id"
                            @selectClinicHealthPlan="(clinicHealthPlan) => form.clinic_health_plan = clinicHealthPlan"
                        />
                        <b-form-invalid-feedback :state="validProp('clinic_health_plan_id')">
                            Campo obrigatório
                        </b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group>
                        <label>Inflator/Deflator</label>
                        <b-input-group append="%">
                            <b-form-input 
                                v-model="form.increase_decrease"
                                type="number"
                                :state="validProp('increase_decrease')"
                            />
                        </b-input-group>
                        <b-form-invalid-feedback :state="validProp('increase_decrease')">
                            Valor inválido
                        </b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group>
                        <label>Preço unidade</label>
                        <b-input-group append="R$">
                            <money 
                                v-model="form.unitary_value"
                                class="form-control"
                            />
                        </b-input-group>
                        <b-form-invalid-feedback :state="validProp('unitary_value')">
                            Valor inválido
                        </b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
            </b-row>
            
            <b-row>
                <b-col>
                    <b-button 
                        variant="primary" 
                        class="float-right" 
                        :loading="loading"
                        :disabled="loading"
                        @click="save"
                    >
                        Salvar
                    </b-button>
                </b-col>
            </b-row>
        </div>
    </b-modal>
</template>
<script>
import api from '@matMed/brasindice-exception.api'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
    components: {
        Close: () => import('@/assets/icons/close.svg'),
        // ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
        HealthPlanSelect: () => import('@/components/General/HealthPlanSelect')
    },
    props: {
        brasindiceProp: Object,
        item: Object
    },
    data() {
        return {
            form: {},
            loading: false,
            validated: false,
            clinic: getCurrentClinic(),
        }
    },
    methods: {
        close() {
            this.$bvModal.hide('brasindice-exceptions-modal')
        },
        onHidden() {
            this.form = {}
            this.$emit('hidden')
        },
        validProp(prop) {
            // eslint-disable-next-line
            if (!this.validated || prop == 0) return null
            return !!this.form[prop]
        },
        isValidForm() {
            return this.form.clinic_health_plan_id && 
                (this.form.increase_decrease && this.form.increase_decrease !== 0) &&
                (this.form.unitary_value && parseFloat(this.form.unitary_value) > 0)
        },
        async save() {
            this.validated = true 
            if (!this.isValidForm()) return
            const payload = {
                ...this.form,
                brasindice_prop_id: this.brasindiceProp.id
            }

            this.loading = true
            try {
                const { data } = !this.form.id ? await api.create(payload) : await api.update(this.form.id, payload)
                this.$toast.success(`Exceção salva com sucesso!`)
                this.$emit('saved', { ...payload, id: data.id })
                this.close()
            } catch (error) {
                this.$toast.error(error.message)
            } finally {
                this.validated = false
                this.loading = false
            }
        },
    },
    watch: {
        type(value) {
            if (value) {
                this.form.type = value
            }
        },
        item(data) {
            if (!data) {
                this.form = { type: this.type }
                return
            }
            
            this.form = {
                ...data,
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .content {
        padding: 1rem;
    }
    .module-title {
        font-size: 16px;
        font-weight: 700;
        color: var(--blue-500);
        margin-bottom: 1rem;
    }
    .radio-group {
        margin-top: 7px;
    }
</style>
<style lang="scss">
#brasindice-exceptions-modal {
    .modal-body {
        padding: 0 !important;
        .header {
            padding: 24px;
            border-bottom: 1px solid var(--neutral-200);
            .title {
                font-weight: 600;
                font-size: 18px;
                color: var(--type-active);
                margin: 0;
            }
            .close {
                width: 24px;
                height: 24px;
                fill: black;
                cursor: pointer;
            }
        }
    }
    .form-control {
        border-radius: 8px 0 0 8px !important;
    }
}
</style>